import React, { Fragment } from "react";
import Container from "../components/container/container";

const style = {
  nav: {
    width: "100vw",
    height: "80px",
    backgroundColor: "#fff",
    // "position":"fixed",
    boxShadow: "1px 1px 2px rgba(0,0,0,0.4)",
    display: "flex",
    alignItems: "center",
    fontSize: 35,
    fontWeight: 800,
    color: "green",
  },
  navInner: {
    marginLeft: "auto",
    marginRight: "auto",
    width: "1240px",
    paddingLeft: "0.8%",
  },
};

const TermsAndConditions = () => {
  return (
    <Fragment>
      <nav style={style.nav}>
        <div style={style.navInner}>Grocy</div>
      </nav>
      <Container>
        <main className="legals">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <h1>Privacy Policy</h1>
                <p>
                  This Privacy Policy describes how your personal information is
                  collected, used, and shared when you visit or make a purchase
                  from grocy.shop (the “Site”).
                </p>
                <h2>PERSONAL INFORMATION WE COLLECT</h2>
                <p>
                  When you visit the Site, we automatically collect certain
                  information about your device, including information about
                  your web browser, IP address, time zone, and some of the
                  cookies that are installed on your device. Additionally, as
                  you browse the Site, we collect information about the
                  individual web pages or products that you view, what websites
                  or search terms referred you to the Site, and information
                  about how you interact with the Site. We refer to this
                  automatically-collected information as “Device Information.”
                </p>

                <p>
                  We collect Device Information using the following
                  technologies:
                </p>
                <p>
                  - “Cookies” are data files that are placed on your device or
                  computer and often include an anonymous unique identifier. For
                  more information about cookies, and how to disable cookies,
                  visit{" "}
                  <a href="https://www.allaboutcookies.org">
                    https://www.allaboutcookies.org
                  </a>
                  .
                </p>
                <p>
                  - “Web beacons,” “tags,” and “pixels” are electronic files
                  used to record information about how you browse the Site.
                </p>
                <p>
                  Additionally when you make a purchase or attempt to make a
                  purchase through the Site, we collect certain information from
                  you, including your name, billing address, shipping address,
                  payment information (including credit card numbers) and phone
                  number. We refer to this information as “Order Information.”
                </p>

                <p>
                  When we talk about “Personal Information” in this Privacy
                  Policy, we are talking both about Device Information and Order
                  Information.
                </p>
                <h2>HOW DO WE USE YOUR PERSONAL INFORMATION?</h2>
                <p>
                  We use the Order Information that we collect generally to
                  fulfill any orders placed through the Site (including
                  processing your payment information, arranging for shipping,
                  and providing you with invoices and/or order confirmations).
                  Additionally, we use this Order Information to: Communicate
                  with you; Screen our orders for potential risk or fraud; We
                  use the Device Information that we collect to help us screen
                  for potential risk and fraud (in particular, your IP address),
                  and more generally to improve and optimize our Site (for
                  example, by generating analytics about how our customers
                  browse).
                </p>
                <h2>SHARING YOUR PERSONAL INFORMATION</h2>
                <p>
                  We share your Personal Information with third parties to help
                  us use your Personal Information, as described above. For
                  example, we use Stripe to process our payments--you can read
                  more about how Stripe uses your Personal Information here:
                  https://stripe.com/gb/privacy. We also use Google Analytics to
                  help us understand how our customers use the Site--you can
                  read more about how Google uses your Personal Information
                  here: https://www.google.com/intl/en/policies/privacy/. You
                  can also opt-out of Google Analytics here:
                  https://tools.google.com/dlpage/gaoptout.
                </p>
                <h2>DO NOT TRACK</h2>
                <p>
                  Please note that we do not alter our Site’s data collection
                  and use practices when we see a Do Not Track signal from your
                  browser.
                </p>
                <h2>YOUR RIGHTS</h2>
                <p>
                  If you are a European resident, you have the right to access
                  personal information we hold about you and to ask that your
                  personal information be corrected, updated, or deleted. If you
                  would like to exercise this right, please contact us through
                  the contact information below.
                </p>
                <p>
                  Additionally, if you are a European resident we note that we
                  are processing your information in order to fulfill contracts
                  we might have with you (for example if you make an order
                  through the Site), or otherwise to pursue our legitimate
                  business interests listed above. Additionally, please note
                  that your information will be transferred outside of Europe,
                  including to Canada and the United States.
                </p>
                <h2>DATA RETENTION</h2>
                <p>
                  When you place an order through the Site, we will maintain
                  your Order Information for our records unless and until you
                  ask us to delete this information.
                </p>
                <h2>MINORS</h2>
                <p>
                  The Site is not intended for individuals under the age of 18.
                </p>
                <h2>CHANGES</h2>
                <p>
                  We may update this privacy policy from time to time in order
                  to reflect, for example, changes to our practices or for other
                  operational, legal or regulatory reasons.
                </p>
                <h2>CONTACT US</h2>
                <p>
                  For more information about our privacy practices, if you have
                  questions, or if you would like to make a complaint, please
                  contact us by phone/whatsapp at +447376421501
                </p>
              </div>
            </div>
          </div>
        </main>
      </Container>
    </Fragment>
  );
};

export default TermsAndConditions;
